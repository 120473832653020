import { useSelector } from 'react-redux'
import { Divider, IconButton, makeStyles } from '@material-ui/core'
import { Delete, Photo, Visibility } from '@material-ui/icons'
import { List, ListItem, ListItemText } from '@mui/material'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/inputFilesStyles'

const useStyles = makeStyles(styles)

const inputFiles = ({ field, reference, stateFile, setStateFile, name }) => {
	const classes = useStyles()
	const { userInfo } = useSelector((state) => state.userLogin)

	const selectFileHandler = (currentRef) => {
		currentRef.current.click()
	}

	const handleFileChange = (e) => {
		e.preventDefault()
		setStateFile([...e.target.files])
	}

	const handleFileRemove = () => {
		setStateFile('')
	}
	return (
		<List dense={true}>
			<ListItem
				className={classes.listItem}
				secondaryAction={
					<>
						{stateFile.length < 1 ? (
							<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(reference)}>
								<Photo fontSize='inherit' />
							</IconButton>
						) : (
							<>
								<IconButton
									aria-label='visibility'
									size='small'
									onClick={() => {
										window.open(
											typeof stateFile === 'object'
												? URL.createObjectURL(stateFile[0])
												: getUrlToOpenFile(stateFile, userInfo),
										)
									}}
								>
									<Visibility fontSize='inherit' />
								</IconButton>
								<IconButton
									aria-label='delete'
									size='small'
									onClick={() => {
										handleFileRemove(stateFile)
									}}
								>
									<Delete fontSize='inherit' color={'error'} />
								</IconButton>
							</>
						)}
					</>
				}
			>
				<ListItemText className={classes.listItemText} primary={name} />
			</ListItem>
			<div className='fileinput'>
				<input
					type='file'
					name={field}
					id={field}
					accept={'image/*,.pdf'}
					value=''
					ref={reference}
					onChange={handleFileChange}
				/>
			</div>
			<Divider variant='fullWidth' className={classes.divider} />
		</List>
	)
}

export default inputFiles
