import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Login } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import statisticsLoginColumns from './helpers/login-table-headers'
import { getUsersLastLoginLogs } from 'local_redux/actions/logActions'
import { usersLastLoginLogsMapper } from './mappers/users-last-login-logs-mapper'
import { LOG_USERS_LAST_LOGIN_RESET } from 'local_redux/constants/logConstants'

const StatisticsLoginScreen = () => {
	const dispatch = useDispatch()

	const [data, setData] = useState([])

	const { successLogUsersLastLogin, logUsersLastLoginData, errorLogUsersLastLogin, loadingLogUsersLastLogin } =
		useSelector(({ logUsersLastLogin }) => logUsersLastLogin)

	useEffect(() => {
		dispatch(getUsersLastLoginLogs())
	}, [])
	useEffect(() => {
		if (successLogUsersLastLogin) {
			const userLogsList = usersLastLoginLogsMapper(logUsersLastLoginData)
			setData(userLogsList)
		}
	}, [successLogUsersLastLogin])
	useEffect(() => {
		return () => dispatch({ type: LOG_USERS_LAST_LOGIN_RESET })
	}, [])

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Login />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<ReactVirtualizedTable
							columns={statisticsLoginColumns()}
							data={data}
							loading={Boolean(loadingLogUsersLastLogin)}
							success={Boolean(successLogUsersLastLogin)}
							error={errorLogUsersLastLogin}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default StatisticsLoginScreen
