import { cardTitle, grayColor } from 'assets/jss/material-dashboard-pro-react.js'

const userProfileStyles = {
	cardTitle,
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		'& small': {
			fontSize: '80%',
			fontWeight: '400',
		},
	},
	cardCategory: {
		marginTop: '10px',
		color: grayColor[0] + ' !important',
		textAlign: 'center',
	},
	description: {
		color: grayColor[0],
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '2px',
		gap: '3px',
	},
	updateProfileButton: {
		float: 'right',
	},
	confirmBtnCssClass: {
		backgroundColor: '#c0bbac',
		color: '#fff',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#fff',
		},
	},

	logotypeContainer: {
		width: '150px',
		height: '150px',
		position: 'relative',
	},

	deleteLogoButton: {
		position: 'absolute',
		top: '0',
		right: '0',
		backgroundColor: '#f44336',
		color: 'white',
		borderRadius: '50%',
	},

	logotype: {
		height: '150px',
		width: '150px',
	},
}
export default userProfileStyles
