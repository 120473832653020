import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardBody from 'components/Card/CardBody'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { seeInfoLogsMapper } from './mappers/see-Info-mapper'
import { seeInfoColumns } from './helpers/see-info-columns'
import { getSeeInfoLogs } from 'local_redux/actions/logActions'
import { LOG_SEE_INFO_PROJECT_ACTION, LOG_PROJECT_SEE_INFO_RESET } from 'local_redux/constants/logConstants'
import styles from './styles/seeInfoScreenStyles'

const useStyles = makeStyles(styles)

const SeeInfoScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [data, setData] = useState([])

	const { logSeeInfoData, loadingLog, successLogRequest, errorLogSeeInfo } = useSelector(({ logSeeInfo }) => logSeeInfo)

	useEffect(() => {
		dispatch(getSeeInfoLogs({ action: 'action', logType: LOG_SEE_INFO_PROJECT_ACTION }))
	}, [])
	useEffect(() => {
		if (successLogRequest) {
			const logs = seeInfoLogsMapper(logSeeInfoData)
			setData(logs)
		}
	}, [successLogRequest])
	useEffect(() => {
		return () => dispatch({ type: LOG_PROJECT_SEE_INFO_RESET })
	}, [])

	return (
		<GridContainer>
			<GridItem xs={12} className={classes.rootItem}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>Registros de ver información</h4>
					</CardHeader>
					<CardBody>
						<ReactVirtualizedTable
							columns={seeInfoColumns()}
							data={data}
							loading={Boolean(loadingLog)}
							success={Boolean(successLogRequest)}
							error={errorLogSeeInfo}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default SeeInfoScreen
