import { format } from 'date-fns'

export const usersLastLoginLogsMapper = (list) => {
	return list?.map((userLog) => {
		return {
			id: userLog._ld,
			name: userLog.user.userName,
			lastLogin: format(new Date(userLog.lastLogin), 'dd/MM/yyyy - hh:mm bbbb'),
		}
	})
}
