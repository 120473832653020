import { matchRoleName } from 'shared/matchData/matchData'

export const usersListMapper = (usersList) => {
	return usersList?.map((item) => {
		return {
			id: item._id,
			email: item.email,
			role: matchRoleName(item.role),
		}
	})
}
