import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'

const RetentionInputs = ({ retention, setRetention }) => {
	return (
		<GridContainer>
			<GridItem xs={12}>
				<CustomInput
					labelText='Pais *'
					id='country'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: retention.country,
						onChange: (e) => {
							setRetention({ ...retention, country: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12}>
				<CustomInput
					labelText='Retención *'
					id='retention'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: retention.retention,
						onChange: (e) => {
							setRetention({ ...retention, retention: e.target.value })
						},
						type: 'number',
						required: true,
					}}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default RetentionInputs
