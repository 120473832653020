import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { ReportGmailerrorred } from '@mui/icons-material'
import { Tabs, Tab, Box } from '@mui/material'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReportBody from './components/ReportBody'
import ReportFilterButton from './components/ReportFilterButton'
import ReportFilter from './components/ReportFilter'
import { reportScreenMapper } from './mappers/reportScreenMapper'
import { LOG_SEARCH_RESET, LOG_BY_TYPE_RESET } from 'local_redux/constants/logConstants'
import styles from './styles/reportScreenStyles'

const useStyles = makeStyles(styles)

const ReportScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const initialState = {
		type: '',
		log: '',
		startDate: null,
		endDate: null,
	}

	const [filter, setFilter] = useState(initialState)
	const [value, setValue] = useState('investment')
	const [isErrorType, setIsErrorType] = useState('action')
	const [data, setData] = useState([])

	const { logByTypeData, errorLogByType } = useSelector(({ logByType }) => logByType)
	const { successLogSearch, logSearchData } = useSelector(({ logSearch }) => logSearch)

	useEffect(() => {
		if (successLogSearch) {
			setData(reportScreenMapper({ logSearchData, value }))
		}
	}, [logSearchData, successLogSearch])

	useEffect(() => {
		return () => dispatch({ type: LOG_SEARCH_RESET })
	}, [dispatch])

	const handleChange = (event, newValue) => {
		setValue(newValue)
		setFilter(initialState)
		if (logSearchData) {
			dispatch({ type: LOG_SEARCH_RESET })
		}
		if (logByTypeData) {
			dispatch({ type: LOG_BY_TYPE_RESET })
		}
	}
	return (
		<>
			<GridContainer>
				<GridItem xs={12}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<ReportGmailerrorred />
							</CardIcon>
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									<Box sx={{ width: '100%' }}>
										<GridContainer>
											<GridItem xs={12} sm={6}>
												<Tabs
													value={value}
													onChange={handleChange}
													textColor='inherit'
													indicatorColor='primary'
													aria-label='secondary tabs example'
												>
													<Tab value='investment' label='Inversiones' />
													<Tab value='login' label='Login' />
												</Tabs>
											</GridItem>
										</GridContainer>
									</Box>
									<GridContainer className={classes.gridContainer}>
										<ReportFilter
											logType={value}
											setIsErrorType={setIsErrorType}
											filter={filter}
											setFilter={setFilter}
										/>
									</GridContainer>
									<GridContainer className={classes.buttonContainer}>
										<GridItem xs={12} sm={6} md={5} lg={3}>
											<ReportFilterButton
												setFilter={setFilter}
												initialState={initialState}
												filter={filter}
												setIsErrorType={setIsErrorType}
											/>
										</GridItem>
										{errorLogByType && (
											<GridItem xs={12} md={12}>
												<p>{errorLogByType}</p>
											</GridItem>
										)}
									</GridContainer>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			{logSearchData && <ReportBody isErrorType={isErrorType} data={data} filter={filter} value={value} />}
		</>
	)
}

export default ReportScreen
