import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { PeopleAlt, ArrowBack } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import ReactTable from 'components/ReactTable/ReactTable'

const InvestorTable = ({ handleInvestorTable }) => {
	const [investorData, setInvestorData] = useState([])

	const { kpisListData } = useSelector((state) => state.kpisList)

	useEffect(() => {
		const data = kpisListData.investorsData.map((item) => {
			return {
				id: item._id,
				investor: `${item.name} ${item.lastName}`,
				createdAt: format(new Date(item.createdAt), 'dd-MM-yyyy'),
				actions: <div className='actions-right'></div>,
			}
		})
		setInvestorData(data)
	}, [])

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color='primary' icon>
					<CardIcon color='primary'>
						<PeopleAlt />
					</CardIcon>

					<h4 style={{ color: 'black', cursor: 'pointer' }} onClick={handleInvestorTable}>
						<ArrowBack style={{ fontSize: '8px', paddingTop: '8px' }} />
						<b>Volver a KPIs</b>
					</h4>
				</CardHeader>
				<CardBody>
					<ReactTable
						columns={[
							{
								Header: 'Perfil Inversor',
								accessor: 'investor',
								size: 'small',
							},
							{
								Header: 'Alta de Inversor',
								accessor: 'createdAt',
								size: 'small',
							},
							{
								Header: 'Acciones',
								accessor: 'actions',
								size: 'small',
							},
						]}
						data={investorData}
						displayButtons={true}
					/>
				</CardBody>
			</Card>
		</GridItem>
	)
}

export default InvestorTable
