import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { RETENTION_DELETE_RESET } from 'local_redux/constants/retentionConstants'
import { deleteRetention } from 'local_redux/actions/retentionActions'
import styles from '../styles/deleteRetentionModalstyles'

const useStyles = makeStyles(styles)

const DeleteRetentionModal = ({ handleCloseDeleteRetentionModal, deleteRetentionModal, showDeleteRetentionInfo }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingRetentionDelete, successRetentionDelete, errorRetentionDelete } = useSelector(
		(state) => state.retentionDelete,
	)

	useEffect(() => {
		let timeOut
		if (successRetentionDelete) {
			timeOut = setTimeout(() => {
				handleCloseDeleteRetentionModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successRetentionDelete])

	useEffect(() => {
		return () => dispatch({ type: RETENTION_DELETE_RESET })
	}, [dispatch])

	const handleDeleteRetention = (e) => {
		e.preventDefault()
		dispatch(deleteRetention(showDeleteRetentionInfo._id))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={deleteRetentionModal}
			keepMounted
			onClose={handleCloseDeleteRetentionModal}
			aria-labelledby='delete-retention-title'
			aria-describedby='delete-retention-description'
		>
			<form onSubmit={handleDeleteRetention}>
				<DialogTitle id='delete-retention-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleCloseDeleteRetentionModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Eliminar Retención</h4>
				</DialogTitle>
				<DialogContent id='delete-retention-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Está seguro que quiere eliminar la retención del pais <b>{showDeleteRetentionInfo?.country}</b>?
							</Typography>
						</GridItem>
					</GridContainer>
					{errorRetentionDelete && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorRetentionDelete} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={handleCloseDeleteRetentionModal} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button type='onSubmit' color={successRetentionDelete ? 'success' : 'primary'} block>
								{loadingRetentionDelete ? 'Eliminando' : successRetentionDelete ? 'Eliminado' : 'Eliminar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default DeleteRetentionModal
