import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  gridButton: {
    textAlign: 'end',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'end',
  },
  cardIconTitle: {
    color: grayColor[0],
  },
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },
  ...modalStyle(theme),
})

export default styles
