import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import ProfileAvatar from './ProfileAvatar'
import { matchRoleName } from 'shared/matchData/matchData'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'

const TopCardProfile = ({ userInfo, classes }) => {
	return (
		<Card profile>
			<ProfileAvatar avatar={getUrlToOpenFile(userInfo.avatar, userInfo)} />
			<CardBody profile>
				<h6 className={classes.cardCategory}>Rol · {matchRoleName(userInfo?.role)}</h6>
				<h4 className={classes.cardTitle}>
					{userInfo.name} {userInfo.lastName === 'null' || userInfo.lastName === undefined ? '' : userInfo.lastName}
				</h4>
				<p className={classes.description}>Correo Electrónico · {userInfo.email}</p>
			</CardBody>
		</Card>
	)
}

export default TopCardProfile
