import { useState } from 'react'
import { useSelector } from 'react-redux'
import { PermIdentity } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Clearfix from 'components/Clearfix/Clearfix'
import UploadDocumentsFiles from './UploadDocumentsFiles'
import UserInvestorProfileInputs from './UserInvestorProfileInputs'
import role from 'config/roles/roles'
import { complexPasswordValidation } from 'shared/validators/inputValidators'

const ProfileFormCard = ({
	classes,
	userInformation,
	setUserInfo,
	errorPasswordValidation,
	setErrorPasswordValidation,
	dniFront,
	setDniFront,
	dniBack,
	setDniBack,
	dniPDF,
	setDniPDF,
	dataSender,
}) => {
	const [fieldType, setFieldType] = useState('')

	const { userInfo } = useSelector((state) => state.userLogin)
	const { loadingUpdateMyInfo, successUpdateMyInfo, errorUpdateMyInfo } = useSelector((state) => state.userUpdateMyInfo)

	const updateProfileHandler = (e) => {
		e.preventDefault()
		if (userInformation.password && !complexPasswordValidation(userInformation.password)) {
			return setErrorPasswordValidation('La contraseña no cumple las condiciones mínimas')
		}
		if (userInfo?.role === role.INVESTOR_ROLE) {
			if (userInformation.email !== userInfo.email && !userInformation.isChangeEmail) {
				return setIsChangeEmailModal(true)
			}
		}

		dataSender(userInformation)
	}

	return (
		<Card>
			<CardHeader color='primary' icon>
				<CardIcon color='primary' style={{ color: '#fff' }}>
					<PermIdentity />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Actualizar Perfil</h4>
			</CardHeader>
			<CardBody>
				<form onSubmit={updateProfileHandler}>
					<GridContainer>
						<GridItem xs={12}>
							<UserInvestorProfileInputs
								setUserInfo={setUserInfo}
								userInformation={userInformation}
								errorPasswordValidation={errorPasswordValidation}
							/>
						</GridItem>
						<GridItem xs={12}>
							{userInfo?.role === 'investor' && (
								<UploadDocumentsFiles
									dniFront={dniFront}
									setDniFront={setDniFront}
									dniBack={dniBack}
									setDniBack={setDniBack}
									dniPDF={dniPDF}
									setDniPDF={setDniPDF}
									fieldType={fieldType}
									setFieldType={setFieldType}
								/>
							)}
						</GridItem>
						{errorUpdateMyInfo && (
							<GridItem xs={12} sm={12}>
								<SnackbarContent message={errorUpdateMyInfo} color='danger' />
							</GridItem>
						)}
					</GridContainer>{' '}
					{errorPasswordValidation && !complexPasswordValidation(userInformation.password) && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorPasswordValidation} color='danger' />
							</GridItem>
						</GridContainer>
					)}
					{/* <CardActions className={classes.cardAction}> */}
					<GridContainer>
						<GridItem xs={12} className={classes.gridButton}>
							<Button type='submit' color={successUpdateMyInfo && 'success'} className={classes.updateProfileButton}>
								{loadingUpdateMyInfo
									? 'Actualizando...'
									: successUpdateMyInfo
									? 'Usuario Actualizado'
									: 'Actualizar Perfil'}
							</Button>
						</GridItem>
					</GridContainer>
					<Clearfix />
					{/* </CardActions> */}
				</form>
			</CardBody>
		</Card>
	)
}

export default ProfileFormCard
