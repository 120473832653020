import { useSelector } from 'react-redux'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { reportScreenInvestmentsColumns } from '../helpers/reportScreenInvestmentsColumns'

const ReportInvestments = ({ data, type, filter }) => {
	const { successLogSearch, loadingLogSearch, errorLogSearch } = useSelector(({ logSearch }) => logSearch)
	return (
		<ReactVirtualizedTable
			data={data}
			columns={reportScreenInvestmentsColumns({ type, filter })}
			loading={Boolean(loadingLogSearch)}
			success={Boolean(successLogSearch)}
			error={errorLogSearch}
		/>
	)
}

export default ReportInvestments
