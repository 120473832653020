import CardAvatar from 'components/Card/CardAvatar'

const ProfileAvatar = ({ avatar }) => {
	return (
		<CardAvatar profile round={true}>
			<a href='#' onClick={(e) => e.preventDefault()}>
				<img src={avatar} alt='...' />
			</a>
		</CardAvatar>
	)
}

export default ProfileAvatar
