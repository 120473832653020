import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QRCode from 'qrcode'
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { List, ListItem } from '@mui/material'
import { Close } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import SweetAlert from 'react-bootstrap-sweetalert'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { postUserMFACodeValidate } from 'local_redux/actions/userActions'
import { USER_MFA_CODE_VALIDATE_RESET } from 'local_redux/constants/userConstants'
import styles from '../styles/setupMfaModalStyles'

const useStyles = makeStyles(styles)

const SetupMfaModal = ({ qrCodeUrl, mfaSecret, openModal, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [code, setCode] = useState('')
	const [qrImage, setQrImage] = useState('')
	const [errorQr, setErrorQr] = useState('')
	const [error, setError] = useState('')
	const [alert, setAlert] = useState(null)

	const { loadingUserMFACodeValidate, successUserMFACodeValidate, userMFACodeValidate, errorUserMFACodeValidate } =
		useSelector((state) => state.userMFACodeValidate)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (qrCodeUrl) {
			// eslint-disable-next-line no-use-before-define
			showQRinUI()
		}
	}, [qrCodeUrl])
	useEffect(() => {
		if (errorUserMFACodeValidate) {
			setError(errorUserMFACodeValidate)
		}
	}, [errorUserMFACodeValidate])
	useEffect(() => {
		if (successUserMFACodeValidate && userMFACodeValidate) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					// eslint-disable-next-line no-use-before-define
					onConfirm={() => hideAlert()}
					// eslint-disable-next-line no-use-before-define
					onCancel={() => hideAlert()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					MFA Activado Correctamente
				</SweetAlert>,
			)
		}
	}, [successUserMFACodeValidate, userMFACodeValidate])

	const hideAlert = () => {
		localStorage.setItem('mfact', true)
		setAlert(null)
		setQrImage('')
		setCode('')
		dispatch({ type: USER_MFA_CODE_VALIDATE_RESET })
		handleClose()
	}
	const showQRinUI = () => {
		QRCode.toDataURL(qrCodeUrl, function (err, data_url) {
			if (err) setErrorQr(err)
			setQrImage(data_url)
		})
	}
	const validateCode = (e) => {
		if (error) {
			dispatch({ type: USER_MFA_CODE_VALIDATE_RESET })
			setError('')
		}
		setCode(e)
	}
	const validateCodeHandler = () => {
		const info = {
			userId: userInfo._id,
			code,
			mfaSecret,
		}
		dispatch(postUserMFACodeValidate(info))
	}

	return (
		<>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal,
				}}
				open={openModal}
				onClose={handleClose}
				keepMounted
				aria-labelledby='setup-mfa-modal-title'
				aria-describedby='setup-mfa-description'
			>
				<DialogTitle id='setup-mfa-modal-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleClose}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Configuración Inicial MFA</h4>
				</DialogTitle>
				<DialogContent id='enable-mfa-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<List>
								<ListItem>
									<p>
										<b>1.</b> Descargue e instale la aplicación <b>Microsoft Authenticator</b> desde la tienda de
										aplicaciones de su dispositivo.
									</p>
								</ListItem>
								<ListItem>
									<GridContainer>
										<GridItem xs={12}>
											<p>
												<b>2.</b> Ingrese a la aplicación y acepte los términos de ser necesario.
											</p>
										</GridItem>
									</GridContainer>
								</ListItem>
								<ListItem>
									<GridContainer>
										<GridItem xs={12}>
											<p>
												<b>3.</b> En la pantalla de Iniciar Sesión solo debe darle click al botón{' '}
												<b>Escanear un código QR</b> y luego aceptar los permisos para utilizar la cámara de ser
												necesario.
											</p>
										</GridItem>
										<GridItem xs={12}>
											{qrImage && <img src={qrImage} />}
											{errorQr && <div>{errorQr}</div>}
										</GridItem>
										<GridItem xs={12}>
											<p>
												<b>4.</b> En este punto se le añadió un ítem llamado <b>(Shareholders - Velzia Group)</b>.
												Ingrese en él para generar códigos.
											</p>
										</GridItem>
										<GridItem xs={12}>
											<p>
												<b>5.</b> Ingrese el código de 6 dígitos que genera la aplicación.
											</p>
										</GridItem>
										<GridItem xs={12}>
											<CustomInput
												labelText='(6 Dígitos)'
												id='mfa-6-digits'
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													maxLength: 6,
													minLength: 6,
													name: 'mfa-6-digits',
													type: 'text',
													onChange: (e) => validateCode(e.target.value),
												}}
												value={code}
											/>
										</GridItem>
										{error && (
											<GridItem xs={12}>
												<SnackbarContent message={error} color='danger' />
											</GridItem>
										)}
									</GridContainer>
								</ListItem>
								<ListItem>
									<Button
										block
										disabled={code === '' || loadingUserMFACodeValidate}
										onClick={() => {
											validateCodeHandler()
										}}
									>
										{loadingUserMFACodeValidate ? 'Confirmando...' : 'Confirmar'}
									</Button>
								</ListItem>
							</List>
						</GridItem>
					</GridContainer>
				</DialogContent>
			</Dialog>
			{alert}
		</>
	)
}

export default SetupMfaModal
