const statisticsLoginColumns = () => {
	return [
		{
			header: 'Usuario',
			accessor: 'name',
		},
		{
			header: 'Ultimo inicio de sesión',
			accessor: 'lastLogin',
		},
	]
}

export default statisticsLoginColumns
