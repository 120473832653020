import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core'
import { PhoneAndroid, AlternateEmail, LockPerson } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardAvatar from 'components/Card/CardAvatar'
import Button from 'components/CustomButtons/Button'
import SetupMfaModal from './components/SetupMfaModal'
import EnableConfirmationModal from './components/EnableConfirmationModal'
import DisableConfirmationModal from './components/DisableConfirmationModal'
import styles from './styles/userProfileStyles'

const useStyles = makeStyles(styles)

const UserSecurityScreen = () => {
	const classes = useStyles()

	const [showModalPassword, setShowModalPassword] = useState(false)
	const [showModalCode, setShowModalCode] = useState(false)
	const [showDisableMFAModal, setShowDisableMFAModal] = useState(false)
	const [mfaSecret, setMfaSecret] = useState('')
	const [qrCodeUrl, setQrCodeUrl] = useState('')
	const [mfaActive, setMfaActive] = useState(false)

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (userInfo && userInfo.mfaSecretConfig?.base32) {
			setMfaActive(true)
		} else {
			setMfaActive(false)
		}
	}, [userInfo])

	const handleClosePassword = () => {
		setShowModalPassword(false)
		setShowModalCode(true)
	}
	const handleCloseDisableModal = () => {
		setShowDisableMFAModal(false)
	}
	const handleCloseCode = () => {
		setShowModalCode(false)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} md={6} style={{ margin: 'auto' }}>
					<Card profile>
						<CardAvatar profile>
							<a href='#' onClick={(e) => e.preventDefault()}>
								<img src={`${axios.defaults.baseURL}/public/assets/img/avatars/avatar.jpg`} alt='...' />
							</a>
						</CardAvatar>
						<CardBody profile>
							<h4 className={classes.cardTitle}>{userInfo?.name}</h4>

							<p className={classes.description}>
								{userInfo?.phone && (
									<>
										<PhoneAndroid />
										{userInfo?.phone}
									</>
								)}
							</p>
							<p className={classes.description}>
								<AlternateEmail />
								{userInfo?.email}
							</p>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} md={8} style={{ margin: 'auto' }}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<LockPerson />
							</CardIcon>

							<h4 className={classes.cardIconTitle}>Multi Factor Authentication (MFA)</h4>
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									{mfaActive ? (
										<p>
											Con el sistema MFA activado, podrá acceder de manera más segura con su contraseña habitual y, a
											continuación un código secreto generado por la aplicación de <b>Microsoft Authenticator.</b>
										</p>
									) : (
										<p>
											Por favor, abra esta página desde un ordenador y descargue la aplicación{' '}
											<b>Microsoft Authenticator</b> en su dispositivo móvil. <br />
											<br /> Con el sistema MFA activado, podrá acceder de manera más segura con su contraseña habitual
											y, a continuación un código secreto generado por la aplicación de <b>Microsoft Authenticator</b>.
										</p>
									)}
								</GridItem>
								<GridItem xs={12}>
									<Button
										block
										disabled={mfaActive}
										onClick={() => {
											if (mfaActive) {
												setShowDisableMFAModal(true)
											} else {
												setShowModalPassword(true)
											}
										}}
									>
										{mfaActive ? 'Habilitado' : 'Habilitar'}
									</Button>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			{showModalPassword && (
				<EnableConfirmationModal
					setMfaSecret={setMfaSecret}
					setQrCodeUrl={setQrCodeUrl}
					openModal={showModalPassword}
					handleClose={handleClosePassword}
				/>
			)}
			{showDisableMFAModal && (
				<DisableConfirmationModal openModal={showDisableMFAModal} handleClose={handleCloseDisableModal} />
			)}
			{showModalCode && (
				<SetupMfaModal
					qrCodeUrl={qrCodeUrl}
					mfaSecret={mfaSecret}
					openModal={showModalCode}
					handleClose={handleCloseCode}
				/>
			)}
		</>
	)
}

export default UserSecurityScreen
