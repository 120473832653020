export const retentionListMapper = (data) => {
	return data?.map((item) => {
		return {
			id: item._id,
			item: item,
			country: item.country,
			retention: `${item.retention} %`,
			actions: '',
		}
	})
}
