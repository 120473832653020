import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Percent } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import RegisterRetentionModal from './components/RegisterRetentionModal'
import DeleteRetentionModal from './components/DeleteRetentionModal'
import { retentionListColumns } from './helpers/retentionListColumns'
import { retentionListMapper } from './mappers/retentionListMapper'
import { getRetentionList } from 'local_redux/actions/retentionActions'
import { RETENTION_UPDATE_RESET } from 'local_redux/constants/retentionConstants'
import { RETENTION_REGISTER_RESET } from 'local_redux/constants/retentionConstants'
import styles from './styles/retentionListScreenStyles'

const useStyles = makeStyles(styles)

const RetentionListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [data, setData] = useState([])
	const [openRegisterRetentionModal, setOpenRegisterRetentionModal] = useState(false)
	const [registerRetentionModalInfo, setRegisterRetentionModalInfo] = useState({})
	const [openDeleteRetentionModal, setOpenDeleteRetentionModal] = useState(false)
	const [deleteRetentionModalInfo, setDeleteRetentionModalInfo] = useState({})

	const { loadingRetentionList, successRetentionList, retentionListData, errorRetentionList } = useSelector(
		(state) => state.retentionList,
	)
	const { successRetentionRegister, retentionRegisterData } = useSelector((state) => state.retentionRegister)
	const { successRetentionUpdate, retentionUpdateData } = useSelector((state) => state.retentionUpdate)
	const { successRetentionDelete, retentionDeleteData } = useSelector((state) => state.retentionDelete)

	useEffect(() => {
		if (!successRetentionList) {
			dispatch(getRetentionList())
		}
	}, [])
	useEffect(() => {
		if (successRetentionList) {
			const retentions = retentionListMapper(retentionListData)

			setData(retentions)
		}
	}, [successRetentionList])
	useEffect(() => {
		if (successRetentionUpdate) {
			let retentions = data.map((item) => {
				if (item.id === retentionUpdateData._id) {
					return { ...retentionUpdateData, retention: `${retentionUpdateData.retention} %` }
				} else {
					return item
				}
			})

			setData(retentions)
			dispatch({ type: RETENTION_UPDATE_RESET })
		}
		if (successRetentionRegister) {
			let retentions = [
				...data,
				{ ...retentionRegisterData, retention: `${retentionRegisterData.retention} %`, item: retentionRegisterData },
			]

			setData(retentions)
			dispatch({ type: RETENTION_REGISTER_RESET })
		}
		// eslint-disable-next-line no-use-before-define
		handleCloseRetentionModal()
	}, [successRetentionUpdate, successRetentionRegister])
	useEffect(() => {
		if (successRetentionDelete) {
			let retentions = data.filter((item) => item.id || item._id !== retentionDeleteData._id)
			setData(retentions)
		}
	}, [successRetentionDelete])

	const handleCloseRetentionModal = () => {
		setOpenRegisterRetentionModal(false)
		setRegisterRetentionModalInfo({})
	}
	const handleOpenRegisterRetentionModal = (retention) => {
		let data = retention ? retention : {}
		setOpenRegisterRetentionModal(true)
		setRegisterRetentionModalInfo(data)
	}
	const handleCloseDeleteRetentionModal = () => {
		setOpenDeleteRetentionModal(false)
		setDeleteRetentionModalInfo({})
	}
	const handleOpenDeleteRetentionModal = (retention) => {
		setOpenDeleteRetentionModal(true)
		setDeleteRetentionModalInfo(retention)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					<Button color='primary' onClick={handleOpenRegisterRetentionModal}>
						Registrar retención
					</Button>
				</GridItem>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon style={{ display: 'flex', justifyContent: 'space-between' }}>
							<CardIcon color='primary'>
								<Percent />
							</CardIcon>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								columns={retentionListColumns({ handleOpenRegisterRetentionModal, handleOpenDeleteRetentionModal })}
								data={data}
								loading={Boolean(loadingRetentionList)}
								success={Boolean(successRetentionList)}
								error={errorRetentionList}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			{openRegisterRetentionModal && (
				<RegisterRetentionModal
					handleCloseRetentionModal={handleCloseRetentionModal}
					registerRetentionModal={openRegisterRetentionModal}
					showRetentionModalInfo={registerRetentionModalInfo}
				/>
			)}
			{openDeleteRetentionModal && (
				<DeleteRetentionModal
					handleCloseDeleteRetentionModal={handleCloseDeleteRetentionModal}
					deleteRetentionModal={openDeleteRetentionModal}
					showDeleteRetentionInfo={deleteRetentionModalInfo}
				/>
			)}
		</>
	)
}

export default RetentionListScreen
