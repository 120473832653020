import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import ReactTable from 'components/ReactTable/ReactTable'
import styles from '../styles/investmentsMadeModalStyles'

const useStyles = makeStyles(styles)

const InvestmentsMadeModal = ({ open, setOpen, investType }) => {
	const classes = useStyles()

	const [profileData, setProfileData] = useState([])

	const { kpisListData } = useSelector((state) => state.kpisList)

	useEffect(() => {
		let data = []
		if (investType === 'profilesWithInvestmentByDateRange') {
			data = kpisListData[investType].map((item) => {
				return {
					id: item._id,
					profile: `${item.businessName}`,
					amount: item.totalInvestmentAmount,
				}
			})
		}
		if (investType === 'investmentsInFirstMonthArray' || investType === 'investmentsAfterFirstMonthArray') {
			data = kpisListData[investType].map((item) => {
				return {
					id: item._id,
					profile: kpisListData.profilesWithInvestmentByDateRange.find(
						(profile) => profile._id.toString() === item.investorProfile.toString(),
					).businessName,
					amount: item.amount,
				}
			})
		}
		setProfileData(data)
	}, [])

	const handleCloseModal = () => {
		setOpen(false)
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={handleCloseModal}
				aria-labelledby='notice-modal-slide-title'
				aria-describedby='notice-modal-slide-description'
			>
				<DialogTitle className={classes.DialogTitle} id='notice-modal-slide-title' disableTypography>
					<h4>
						{investType === 'profilesWithInvestmentByDateRange'
							? 'Inversiones Realizadas'
							: investType === 'investmentsInFirstMonthArray'
							? 'Inversiones Nuevas'
							: investType === 'investmentsAfterFirstMonthArray'
							? 'Reinversiones'
							: 'Listado'}
					</h4>
					<Button justIcon key='close' aria-label='Close' color='transparent' onClick={handleCloseModal}>
						<Close />
					</Button>
				</DialogTitle>

				<DialogContent id='notice-modal-slide-description'>
					<ReactTable
						columns={[
							{
								Header: 'Perfil',
								accessor: 'profile',
								size: 'small',
							},
							{
								Header: 'Monto',
								accessor: 'amount',
								size: 'small',
							},
							{
								Header: 'Acciones',
								accessor: '',
								size: '',
							},
						]}
						data={profileData}
						displayButtons={true}
					/>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default InvestmentsMadeModal
