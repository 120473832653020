const statisticsProjectInfoViewedColumns = (windowsSize) => {
	return windowsSize.width > 600
		? [
				{
					Header: 'Usuario',
					accessor: 'user',
				},
				{
					Header: 'Date',
					accessor: 'viewedDate',
				},

				{
					Header: 'Proyecto',
					accessor: 'project',
				},
		  ]
		: [
				{
					Header: 'Usuario',
					accessor: 'user',
				},
				{
					Header: 'Date',
					accessor: 'viewedDate',
				},
		  ]
}

export default statisticsProjectInfoViewedColumns
