import { FormControl, Select as Selectable, MenuItem, InputLabel } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import { matchGender } from 'shared/matchData/matchData'
import { complexPasswordValidation } from 'shared/validators/inputValidators'

const UserInvestorProfileInputs = ({ setUserInfo, userInformation, errorPasswordValidation }) => {
	const isInvestorRole = userInformation?.role === 'investor'

	const emailErrorHandler = () => {
		if (errorPasswordValidation && userInformation.isDefaultPassword) {
			return true
		}
		if (
			userInformation.password &&
			userInformation.password.length > 3 &&
			!complexPasswordValidation(userInformation.password)
		) {
			return true
		}

		return false
	}

	return (
		<GridContainer style={{ marginBottom: '26px' }}>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText={'Nombre *'}
					id='name'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInformation.name,
						onChange: (e) => setUserInfo({ ...userInformation, name: e.target.value }),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			{isInvestorRole && (
				<GridItem xs={12} md={6}>
					<CustomInput
						labelText={'Apellidos *'}
						id='lastName'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInformation.lastName,
							onChange: (e) => setUserInfo({ ...userInformation, lastName: e.target.value }),
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
			)}
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText={'DNI/NIE *'}
					id='dni'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInformation.dni ? userInformation.dni : '',
						onChange: (e) => setUserInfo({ ...userInformation, dni: e.target.value }),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText={'Email *'}
					id='email'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInformation.email,
						onChange: (e) => setUserInfo({ ...userInformation, email: e.target.value }),
						type: 'email',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<CustomInput
					labelText='Teléfono *'
					id='phone'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInformation.phone,
						onChange: (e) => setUserInfo({ ...userInformation, phone: e.target.value }),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			{isInvestorRole && (
				<>
					<GridItem xs={12} md={6}>
						<CustomInput
							labelText='Teléfono 2 (No principal)'
							id='phone2'
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								value: userInformation?.phone2 || '',
								onChange: (e) => setUserInfo({ ...userInformation, phone2: e.target.value }),
								type: 'text',
							}}
						/>
					</GridItem>
					<GridItem xs={12}>
						<CustomInput
							labelText='Dirección *'
							id='address'
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								value: userInformation.address ? userInformation.address : '',
								onChange: (e) => setUserInfo({ ...userInformation, address: e.target.value }),
								type: 'text',
								required: true,
							}}
						/>
					</GridItem>
				</>
			)}
			<GridItem xs={12}>
				<CustomInput
					labelText='Contraseña'
					id='passphrase'
					error={emailErrorHandler()}
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInformation.password || '',
						onChange: (e) => setUserInfo({ ...userInformation, password: e.target.value }),
						type: 'password',
					}}
				/>
			</GridItem>
			<GridItem xs={12} style={{ fontSize: '11px' }}>
				* La contraseña debe tener minimo 8 caracteres, una mayúscula, una minúscula y un número.
			</GridItem>
			{isInvestorRole && (
				<GridItem xs={12} sm={3}>
					<FormControl fullWidth>
						<InputLabel htmlFor='update-gender'>Género *</InputLabel>
						<Selectable
							value={matchGender(userInformation.gender, true)}
							onChange={(e) => setUserInfo({ ...userInformation, gender: e.target.value })}
							inputProps={{
								name: 'update-gender',
								id: 'update-gender',
							}}
						>
							<MenuItem disabled>Selecciona una Opción</MenuItem>
							{['Mujer', 'Hombre'].map((entityType, index) => (
								<MenuItem value={entityType} key={index}>
									{entityType}
								</MenuItem>
							))}
						</Selectable>
					</FormControl>
				</GridItem>
			)}
		</GridContainer>
	)
}

export default UserInvestorProfileInputs
