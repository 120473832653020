import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import { grayColor } from 'assets/jss/material-dashboard-pro-react.js'

const styles = (theme) => ({
	...modalStyle(theme),
	confirmBtnCssClass: {
		backgroundColor: '#c0bbac',
		color: '#fff',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#fff',
		},
	},
	fileTitle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	container: {
		margin: '15px',
	},
	inputAdornmentIcon: {
		color: grayColor[6],
	},
})

export default styles
