import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputAdornment, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close, Lock, VpnKey } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { disableUserMFA } from 'local_redux/actions/userActions'
import { USER_MFA_CODE_DISABLE_RESET } from 'local_redux/constants/userConstants'
import styles from '../styles/disableConfirmationModalStyles'

const useStyles = makeStyles(styles)

const DisableConfirmationModal = ({ openModal, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [password, setPassword] = useState('')
	const [mfaCodeToDisable, setMfaCodeToDisable] = useState('')
	const [error, setError] = useState('')

	const { loadingUserDisableMFA, successUserDisableMFA, userDisableMFA, errorUserDisableMFA } = useSelector(
		(state) => state.userDisableMFA,
	)

	useEffect(() => {
		if (successUserDisableMFA && userDisableMFA) {
			handleClose()
		}
	}, [successUserDisableMFA])
	useEffect(() => {
		if (errorUserDisableMFA) {
			setError(errorUserDisableMFA)
		}
	}, [errorUserDisableMFA])

	const disableMFAHandler = () => {
		if (password === '' || mfaCodeToDisable === '') {
			return setError('Ingrese su contraseña y código para continuar.')
		}
		const info = {
			password,
			token: mfaCodeToDisable,
		}
		dispatch(disableUserMFA(info))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			onClose={handleClose}
			keepMounted
			aria-labelledby='disable-mfa-modal-title'
			aria-describedby='disable-mfa-description'
		>
			<DialogTitle id='disable-mfa-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleClose}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Deshabilitar MFA</h4>
			</DialogTitle>
			<DialogContent id='disable-mfa-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<p>
							Es muy importante que mantenga una autenticación multifactor activada por seguridad. <br />
							Está a punto de desactivar la autenticación multifactor para acceder al{' '}
							<b>Sistema Integral de Gestión - (SIG)</b>
						</p>
						<p>
							Si está seguro que desea deshabilitar el MFA, ingrese su contraseña habitual de acceso al SIG y el código
							generado por su aplicación de <b>MFA</b>:
						</p>
					</GridItem>
					<GridItem xs={12}>
						<CustomInput
							labelText={'Constraseña'}
							inputProps={{
								type: 'password',
								name: 'passwordmfa',
								onChange: (e) => {
									if (errorUserDisableMFA) {
										dispatch({ type: USER_MFA_CODE_DISABLE_RESET })
										setError('')
									}
									setPassword(e.target.value)
								},
								endAdornment: (
									<InputAdornment position='end'>
										<Lock className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
							}}
							value={password}
						/>
					</GridItem>
					<GridItem xs={12}>
						<CustomInput
							labelText={'MFA de 6 Dígitos'}
							inputProps={{
								maxLength: 6,
								minLength: 6,
								type: 'text',
								name: 'mfa-code',
								onChange: (e) => {
									if (errorUserDisableMFA) {
										dispatch({ type: USER_MFA_CODE_DISABLE_RESET })
										setError('')
									}
									setMfaCodeToDisable(e.target.value)
								},
								endAdornment: (
									<InputAdornment position='end'>
										<VpnKey className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
							}}
							value={mfaCodeToDisable}
						/>
					</GridItem>
					{error && (
						<GridItem xs={12}>
							<SnackbarContent message={error} color='danger' />
						</GridItem>
					)}
					<GridItem xs={12}>
						<Button
							block
							disabled={loadingUserDisableMFA || password === '' || mfaCodeToDisable === ''}
							onClick={disableMFAHandler}
						>
							{loadingUserDisableMFA ? 'Confirmado...' : 'Confirmar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogContent>
		</Dialog>
	)
}

export default DisableConfirmationModal
