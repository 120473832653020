import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import { Assignment, People, AccountBox } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import TimeFilterCard from 'components/TimeFilterCard/TimeFilterCard'
import ProfileTable from './components/ProfileTable'
import Button from 'components/CustomButtons/Button'
import InvestorTable from './components/InvestorTable'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import { getKpisList } from 'local_redux/actions/kpisActions'
import { KPIS_LIST_DATA_RESET } from 'local_redux/constants/kpisConstants'
import InvestmentsMadeModal from './components/InvestmentsMadeModal'

const KpisScreen = () => {
	const classes = {}
	const dispatch = useDispatch()

	const initialState = {
		startDate: null,
		endDate: null,
	}

	const [data, setData] = useState([])
	const [filterDate, setFilterDate] = useState(initialState)
	const [viewProfile, setViewProfile] = useState(false)
	const [viewInvestor, setVietInvestor] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showModalNewInvestments, setShowModalNewInvestments] = useState(false)
	const [showModalReinvestments, setShowModalReinvestments] = useState(false)

	const { filteredRows } = useContext(FilterReactTableContext)

	const { loadingKpisList, kpisListData, succesKpisList } = useSelector((state) => state.kpisList)

	useEffect(() => {
		if (succesKpisList) {
			const firstHandle = handleProfile // eslint-disable-line no-use-before-define
			const secondHandle = handleInvestorTable // eslint-disable-line no-use-before-define

			const kpisData = [kpisListData].map((item, i) => {
				return {
					id: i,
					profiles: item.profiles,
					investors: item.investors,
					investmentsBetweenDates: item.investmentsBetweenDates,
					investmentsInFirstMonth: item.investmentsInFirstMonth,
					investmentsAfterFirstMonth: item.investmentsAfterFirstMonth,
					numOfInvestmentsBetweenDates: item.numOfInvestmentsBetweenDates,
					averageCost: item.averageCost,
					totalLiquidatedAmount: item.totalLiquidatedAmount,
					reinvestmentPercentage: item.reinvestmentPercentage,
					actions: (
						<div className='actions-right'>
							<Tooltip id='profile' title='Ver Perfiles' placement='bottom'>
								<Button justIcon round simple onClick={firstHandle} color='success' className='view'>
									<AccountBox />
								</Button>
							</Tooltip>
							<Tooltip id='investor' title='Ver Inversores' placement='bottom'>
								<Button justIcon round simple onClick={secondHandle} color='success' className='view'>
									<People />
								</Button>
							</Tooltip>
						</div>
					),
				}
			})
			setData(kpisData)
		}
	}, [setData, kpisListData])
	useEffect(() => {
		return () => dispatch({ type: KPIS_LIST_DATA_RESET })
	}, [dispatch])

	const formateEcxelAmount = (ecxelAmount) => ecxelAmount.replace('€', '').replaceAll('.', '').trim()
	const handleFilter = ({ endDate }) => {
		dispatch(getKpisList(format(new Date(filterDate.startDate), 'yyyy-MM-dd'), format(new Date(endDate), 'yyyy-MM-dd')))
	}
	const handleProfile = () => {
		setViewProfile(!viewProfile)
	}
	const handleInvestorTable = () => {
		setVietInvestor(!viewInvestor)
	}
	const handleInvestments = () => {
		setShowModal(true)
	}
	const handleNewInvestments = () => {
		setShowModalNewInvestments(true)
	}
	const handleReinvestments = () => {
		setShowModalReinvestments(true)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} md={5}>
					<TimeFilterCard
						filterDate={filterDate}
						setFilterDate={setFilterDate}
						handleFilter={handleFilter}
						title='Estadísticas entre'
					/>
				</GridItem>
				{loadingKpisList ? (
					<GridItem xs={12} className={classes.loader}>
						<Loader />
					</GridItem>
				) : kpisListData &&
				  !viewProfile &&
				  !viewInvestor &&
				  !showModal &&
				  !showModalNewInvestments &&
				  !showModalReinvestments ? (
					<>
						<GridItem xs={12}>
							<Card>
								<CardHeader color='primary' icon>
									<CardIcon color='primary'>
										<Assignment />
									</CardIcon>
									<h4 className={classes.cardIconTitle}>Listas de Perfiles</h4>
								</CardHeader>
								<GridItem xs={12}>
									{kpisListData && (
										<ExcelDefaultExport
											data={filteredRows?.map((item) => {
												item.investmentsBetweenDates = item.investmentsBetweenDates
													? formateEcxelAmount(item.investmentsBetweenDates)
													: ''
												item.investmentsInFirstMonth = item.investmentsInFirstMonth
													? formateEcxelAmount(item.investmentsInFirstMonth)
													: ''
												item.investmentsAfterFirstMonth = item.investmentsAfterFirstMonth
													? formateEcxelAmount(item.investmentsAfterFirstMonth)
													: ''
												item.averageCost = item.averageCost ? formateEcxelAmount(item.averageCost) : ''
												return item
											})}
											excelName={
												!filterDate.startDate
													? `Kpis mes-${format(new Date(), 'MM-yyyy')}`
													: `Kpis meses-${format(new Date(filterDate.startDate), 'dd-MM-yyyy')}-${format(
															new Date(filterDate.endDate),
															'dd-MM-yyyy',
													  )}`
											}
										/>
									)}
								</GridItem>
								<CardBody>
									{!viewProfile && (
										<ReactTable
											columns={[
												{
													Header: 'Perfiles Nuevos',
													accessor: 'profiles',
													size: 'medium',
													Cell: ({ cell }) => {
														return (
															<p style={{ cursor: 'pointer', color: '#4646c3' }} onClick={handleProfile}>
																{cell.value}
															</p>
														)
													},
												},
												{
													Header: 'Inversores Nuevos',
													accessor: 'investors',
													size: 'medium',
												},
												{
													Header: 'Inversión Realizadas',
													accessor: 'investmentsBetweenDates',
													size: 'medium',
													Cell: ({ cell }) => {
														return (
															<p style={{ cursor: 'pointer', color: '#4646c3' }} onClick={handleInvestments}>
																{cell.value}
															</p>
														)
													},
												},
												{
													Header: 'Inversión Nuevos',
													accessor: 'investmentsInFirstMonth',
													size: 'medium',
													Cell: ({ cell }) => {
														return (
															<p style={{ cursor: 'pointer', color: '#4646c3' }} onClick={handleNewInvestments}>
																{cell.value}
															</p>
														)
													},
												},
												{
													Header: 'Reinversión',
													accessor: 'investmentsAfterFirstMonth',
													size: 'medium',
													Cell: ({ cell }) => {
														return (
															<p style={{ cursor: 'pointer', color: '#4646c3' }} onClick={handleReinvestments}>
																{cell.value}
															</p>
														)
													},
												},
												{
													Header: '# de Inversiones',
													accessor: 'numOfInvestmentsBetweenDates',
													size: 'medium',
												},
												{
													Header: '€/Inversión',
													accessor: 'averageCost',
													size: 'small',
												},
												{
													Header: 'Importe Liquidado Total',
													accessor: 'totalLiquidatedAmount',
													size: 'big',
												},
												{
													Header: '% de Reinversión',
													accessor: 'reinvestmentPercentage',
													size: 'small',
												},
												{
													Header: 'Acciones',
													accessor: 'actions',
													size: 'small',
												},
											]}
											displayButtons={true}
											data={data}
										/>
									)}
								</CardBody>
							</Card>
						</GridItem>
					</>
				) : viewProfile ? (
					<GridItem xs={12}>{viewProfile && <ProfileTable handleProfile={handleProfile} />}</GridItem>
				) : (
					viewInvestor && (
						<GridItem xs={12}>{viewInvestor && <InvestorTable handleInvestorTable={handleInvestorTable} />}</GridItem>
					)
				)}
			</GridContainer>
			{showModal && (
				<InvestmentsMadeModal
					open={showModal}
					setOpen={setShowModal}
					investType={'profilesWithInvestmentByDateRange'}
				/>
			)}
			{showModalNewInvestments && (
				<InvestmentsMadeModal
					open={showModalNewInvestments}
					setOpen={setShowModalNewInvestments}
					investType={'investmentsInFirstMonthArray'}
				/>
			)}
			{showModalReinvestments && (
				<InvestmentsMadeModal
					open={showModalReinvestments}
					setOpen={setShowModalReinvestments}
					investType={'investmentsAfterFirstMonthArray'}
				/>
			)}
		</>
	)
}

export default KpisScreen
