import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { PeopleAlt, ArrowBack } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import ReactTable from 'components/ReactTable/ReactTable'

const ProfileTable = ({ handleProfile }) => {
  const [profiledata, setProfileData] = useState([])

  const { kpisListData } = useSelector((state) => state.kpisList)

  useEffect(() => {
    const data = kpisListData.profilesData.map((item) => {
      return {
        id: item._id,
        businessName: item.businessName,
        createdAt: format(new Date(item.createdAt), 'dd-MM-yyyy'),
        totalInvestmentAmount: item.totalInvestmentAmount,
        actions: <div className='actions-right'></div>,
      }
    })
    setProfileData(data)
  }, [])

  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader color='primary' icon>
          <CardIcon color='primary'>
            <PeopleAlt />
          </CardIcon>

          <h4 style={{ color: 'black', cursor: 'pointer' }} onClick={handleProfile}>
            <ArrowBack style={{ fontSize: '8px', paddingTop: '8px' }} />
            <b>Volver a KPIs</b>
          </h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={[
              {
                Header: 'Razon social',
                accessor: 'businessName',
                size: 'small',
              },
              {
                Header: 'Alta de Perfil',
                accessor: 'createdAt',
                size: 'small',
              },
              {
                Header: 'Inversión',
                accessor: 'totalInvestmentAmount',
                size: 'small',
              },
              {
                Header: 'Acciones',
                accessor: 'actions',
                size: 'small',
              },
            ]}
            data={profiledata}
            displayButtons={true}
          />
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default ProfileTable
