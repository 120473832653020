export const reportScreenLoginColumns = ({ type }) => {
	if (type === 'Error') {
		return [
			{
				header: 'Correo',
				accessor: 'email',
			},
			{
				header: 'Error',
				accessor: 'error',
			},
			{
				header: 'Fecha',
				accessor: 'date',
				type: 'date',
				dateFormat: 'dd/MM/yyyy',
			},
			{
				header: 'Hora',
				accessor: 'hour',
				type: 'date',
				dateFormat: 'hh:mm aaaa',
			},
		]
	} else {
		return [
			{
				header: 'Correo',
				accessor: 'email',
			},
			{
				header: 'Nombre',
				accessor: 'name',
			},
			{
				header: 'Apellido',
				accessor: 'surname',
			},
			{
				header: 'Rol',
				accessor: 'role',
			},
			{
				header: 'Fecha',
				accessor: 'date',
				type: 'date',
				dateFormat: 'dd/MM/yyyy',
			},
			{
				header: 'Hora de log',
				accessor: 'hour',
				type: 'date',
				dateFormat: 'hh:mm aaaa',
			},
		]
	}
}
