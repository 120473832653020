export const reportScreenInvestmentsColumns = ({ type, filter }) => {
	let columns = []
	if (type === 'Error') {
		columns = [
			{
				header: 'Proyecto',
				accessor: 'projectAddress',
			},
			{
				header: 'Usuario',
				accessor: 'user',
			},
			{
				header: 'Perfil',
				accessor: 'profile',
			},
			{
				header: 'Monto',
				accessor: 'mount',
				type: 'currency',
			},
			{
				header: 'Error',
				accessor: 'error',
			},
			{
				header: 'Fecha',
				accessor: 'date',
				type: 'date',
				dateFormat: 'dd/MM/yyyy - hh:mm aaaa',
			},
		]
	} else {
		columns = [
			{
				header: 'Proyecto',
				accessor: 'projectAddress',
			},
			{
				header: 'Usuario',
				accessor: 'user',
			},
			{
				header: 'Perfil',
				accessor: 'profile',
			},
			{
				header: 'Monto',
				accessor: 'mount',
				type: 'currency',
			},
			{
				header: 'Fecha',
				accessor: 'date',
				type: 'date',
				dateFormat: 'dd/MM/yyyy - hh:mm aaaa',
			},
		]
		let isIncomeDeleted = filter.log === ' INCOME_DELETE_DATE_SUCCESS'

		let reasonHeader = [
			{
				header: 'Motivo',
				accessor: 'reason',
			},
		]
		columns = isIncomeDeleted ? [...columns, ...reasonHeader] : columns
	}
	return columns
}
