import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  Select as Selectable,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Clearfix from 'components/Clearfix/Clearfix'
import UserPermissions from 'components/UserPermissions/UserPermissions'
import { matchStatusUser } from 'shared/matchData/matchData'
import { userUpdateInfo } from 'local_redux/actions/userActions'
import { USER_LIST_RESET, USER_UPDATE_RESET } from 'local_redux/constants/userConstants'
import styles from '../styles/updateUserModalStyles'

const useStyles = makeStyles(styles)

const UpdateUserModal = ({ handleCloseModal, updateUserModal, showUpdateUser }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [infoModalData, setModalData] = useState(showUpdateUser)

  const { loadingUserUpdate, successUserUpdate, errorUserUpdate } = useSelector((state) => state.userUpdate)

  useEffect(() => {
    if (successUserUpdate) {
      dispatch({ type: USER_LIST_RESET })
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET })
        handleCloseModal()
      }, 1000)
    }
  }, [successUserUpdate])

  const updateUserHandler = (e) => {
    e.preventDefault()

    const userData = {
      _id: infoModalData._id,
      name: infoModalData.name,
      phone: infoModalData.phone,
      email: infoModalData.email,
      password: infoModalData.password,
      status: infoModalData.status,
      isAdmin: infoModalData.isAdmin,
      isSuper: infoModalData.isSuper,
      isMarketing: infoModalData.isMarketing === undefined ? false : infoModalData.isMarketing,
      isContabilidad: infoModalData.isContabilidad === undefined ? false : infoModalData.isContabilidad,
      isInvestorRelations: infoModalData.isInvestorRelations === undefined ? false : infoModalData.isInvestorRelations,
      isManagement: infoModalData.isManagement === undefined ? false : infoModalData.isManagement,
      isInformation: infoModalData.isInformation === undefined ? false : infoModalData.isInformation,
      personType: infoModalData.personType,
      role: true,
    }

    dispatch(userUpdateInfo(userData))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={updateUserModal}
      keepMounted
      onClose={handleCloseModal}
      aria-labelledby='notice-modal-slide-title'
      aria-describedby='notice-modal-slide-description'
    >
      <DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{`Editar Usuario`}</h4>
      </DialogTitle>
      <DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
        <form onSubmit={updateUserHandler} id='update-userinfo-modal-form' autoComplete='false'>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <CustomInput
                labelText={'Nombre'}
                id='user-name'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: infoModalData.name,
                  onChange: (e) => setModalData({ ...infoModalData, name: e.target.value }),
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                labelText={'Teléfono'}
                id='user-phone'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: infoModalData.phone,
                  onChange: (e) => setModalData({ ...infoModalData, phone: e.target.value }),
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                labelText='Email'
                id='investor-email'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: infoModalData.email,
                  onChange: (e) => setModalData({ ...infoModalData, email: e.target.value }),
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                labelText='Contraseña'
                id='user-password'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: infoModalData?.password || '',
                  onChange: (e) => setModalData({ ...infoModalData, password: e.target.value }),
                  type: 'password',
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControl>
                <InputLabel htmlFor='user-update-type'>Estado</InputLabel>
                <Selectable
                  value={matchStatusUser(infoModalData.status)}
                  onChange={(e) => setModalData({ ...infoModalData, status: e.target.value })}
                  inputProps={{
                    name: 'user-update-type',
                    id: 'user-update-type',
                  }}
                >
                  <MenuItem disabled>Selecciona una Opción</MenuItem>
                  {['Activo', 'Inactivo'].map((entityType, index) => (
                    <MenuItem
                      classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                      value={entityType}
                      key={index}
                    >
                      {entityType}
                    </MenuItem>
                  ))}
                </Selectable>
              </FormControl>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: '30px' }}>
              <UserPermissions permissions={infoModalData} setPermissions={setModalData} modal />
            </GridItem>
          </GridContainer>
          {errorUserUpdate && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorUserUpdate} color='danger' />
              </GridItem>
            </GridContainer>
          )}
          {/* Add error Snackbar to hire */}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              type='submit'
              form='update-userinfo-modal-form'
              color={successUserUpdate ? 'success' : 'primary'}
              block
            >
              {loadingUserUpdate ? 'Actualizando' : successUserUpdate ? 'Usuario Actualiado' : 'Actualizar Usuario'}
            </Button>
            <Clearfix />
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateUserModal
