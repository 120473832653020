import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { postUserConfirmPassword } from 'local_redux/actions/userActions'
import { USER_CONFIRM_PASSWORD_RESET } from 'local_redux/constants/userConstants'
import styles from '../styles/enableConfirmationModalStyles'

const useStyles = makeStyles(styles)

const EnableConfirmationModal = ({ setQrCodeUrl, setMfaSecret, openModal, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [password, setPassword] = useState('')
	const [error, setError] = useState('')

	const { loadingUserConfirmPassword, successUserConfirmPassword, userConfirmPassword, errorUserConfirmPassword } =
		useSelector((state) => state.userConfirmPassword)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (successUserConfirmPassword && userConfirmPassword.status) {
			setMfaSecret(userConfirmPassword.secret)
			setQrCodeUrl(userConfirmPassword.qrUrl)
			handleClose()
		}
	}, [successUserConfirmPassword])
	useEffect(() => {
		if (errorUserConfirmPassword) {
			setError(errorUserConfirmPassword)
		}
	}, [errorUserConfirmPassword])

	const validatePassword = () => {
		if (password === '') {
			return setError('Ingrese su contraseña para continuar.')
		}
		dispatch(postUserConfirmPassword(userInfo.email, password))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			onClose={handleClose}
			keepMounted
			aria-labelledby='enable-mfa-modal-title'
			aria-describedby='enable-mfa-description'
		>
			<DialogTitle id='enable-mfa-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleClose}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Habilitar MFA</h4>
			</DialogTitle>
			<DialogContent id='enable-mfa-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<p>
							Esta a punto de activar la autenticación multifactor, recuerde que debe tener acceso a su dispositivo
							móvil y aplicación de <b>Microsoft Authenticator</b> cada vez que quiera iniciar sesión en{' '}
							<b>Shareholders - Velzia Group</b>
						</p>
						<p>Para continuar ingrese su contraseña habitual de acceso al sistema:</p>
					</GridItem>
					<GridItem xs={12}>
						<CustomInput
							labelText={'Contraseña Habitual'}
							inputProps={{
								type: 'password',
								id: 'passwordmfa',
								name: 'passwordmfa',
								autoComplete: 'new-password',
								onChange: (e) => {
									if (errorUserConfirmPassword) {
										dispatch({ type: USER_CONFIRM_PASSWORD_RESET })
										setError('')
									}
									setPassword(e.target.value)
								},
							}}
							value={password}
						/>
					</GridItem>
					{error && (
						<GridItem xs={12}>
							<SnackbarContent message={error} color='danger' />
						</GridItem>
					)}
					<GridItem xs={12}>
						<Button block disabled={loadingUserConfirmPassword || password === ''} onClick={validatePassword}>
							{loadingUserConfirmPassword ? 'Confirmado...' : 'Confirmar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogContent>
		</Dialog>
	)
}

export default EnableConfirmationModal
