import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, isAfter, isBefore, isSameDay } from 'date-fns'
import { makeStyles } from '@material-ui/core'
import { Assessment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import TimeFilterCard from 'components/TimeFilterCard/TimeFilterCard'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import { getInvestmentsByDateAndInvestor } from 'local_redux/actions/investmentActions'
import { INVESTMENT_BY_DATE_AND_INVESTOR_RESET } from 'local_redux/constants/investmentConstants'
import styles from './styles/stadisticScreenStyles'

const useStyles = makeStyles(styles)

const StadisticScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const initialState = {
		startDate: null,
		endDate: null,
	}

	const [data, setData] = useState([])
	const [filterDate, setFilterDate] = useState(initialState)
	const { filteredRows } = useContext(FilterReactTableContext)

	const { loadingInvestmentsByDateAndInvestor, investmentsByDateAndInvestor } = useSelector(
		(state) => state.investmentsByDateAndInvestor,
	)
	useEffect(() => {
		if (!investmentsByDateAndInvestor) {
			dispatch(getInvestmentsByDateAndInvestor())
		}
	}, [investmentsByDateAndInvestor])

	useEffect(() => {
		return () => dispatch({ type: INVESTMENT_BY_DATE_AND_INVESTOR_RESET })
	}, [dispatch])

	const handleFilter = ({ endDate }) => {
		let investmentsFiltered = investmentsByDateAndInvestor.filter(
			(item) =>
				isSameDay(new Date(item.investmentDate), new Date(filterDate.startDate)) ||
				(isAfter(new Date(item.investmentDate), new Date(filterDate.startDate)) &&
					isBefore(new Date(item.investmentDate), new Date(endDate))) ||
				isSameDay(new Date(item.investmentDate), new Date(endDate)),
		)
		const data = investmentsFiltered.map((item, i) => {
			return {
				id: item._id,
				num: i + 1,
				investmentDate: format(new Date(item.investmentDate), 'dd/MM/yyyy'),
				incomeRecivedStatus: !item.incomeRecivedDate ? 'Pendiente' : 'Ingreso recibido',
				createdAt: format(new Date(item.investorProfile?.createdAt), 'dd-MM-yyyy'),
				businessName: item.investorProfile?.businessName,
				amount: item.amount,
				actions: <div className='actions-right'></div>,
			}
		})
		setData(data)
	}

	return (
		<GridContainer>
			<GridItem xs={12} md={5}>
				<TimeFilterCard
					filterDate={filterDate}
					setFilterDate={setFilterDate}
					handleFilter={handleFilter}
					title='Inversiones entre'
				/>
			</GridItem>
			{data && data.length > 0 && (
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assessment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Prescriptores</h4>
						</CardHeader>
						<CardBody>
							<GridItem>
								<ExcelDefaultExport
									data={filteredRows?.map((item) => {
										item.amount = item && item.amount ? item.amount.replace('€', '').replaceAll('.', '')?.trim() : ''
										return item
									})}
									excelName={
										!filterDate.startDate
											? `Estadísticas de inversiones mes-${format(new Date(), 'MM-yyyy')}`
											: `Estadísticas de inversiones meses-${format(
													new Date(filterDate.startDate),
													'dd-MM-yyyy',
											  )}-${format(new Date(filterDate.endDate), 'dd-MM-yyyy')}`
									}
								/>
							</GridItem>
							{loadingInvestmentsByDateAndInvestor ? (
								<GridItem xs={12} className={classes.loader}>
									<Loader />
								</GridItem>
							) : (
								<ReactTable
									columns={[
										{
											Header: 'Columna',
											accessor: 'num',
											size: 'small',
										},
										{
											Header: 'Razón social',
											accessor: 'businessName',
											size: 'big',
										},
										{
											Header: 'Momento de Inversión',
											accessor: 'investmentDate',
											size: 'small',
										},
										{
											Header: 'Estado',
											accessor: 'incomeRecivedStatus',
											size: 'small',
										},
										{
											Header: 'Importe',
											accessor: 'amount',
											size: 'small',
										},
										{
											Header: 'Alta de Perfil',
											accessor: 'createdAt',
											size: 'small',
										},
										{
											Header: 'Acciones',
											accessor: 'actions',
											size: 'small',
										},
									]}
									data={data}
									displayButtons={true}
								/>
							)}
						</CardBody>
					</Card>
				</GridItem>
			)}
		</GridContainer>
	)
}

export default StadisticScreen
