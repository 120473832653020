import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { FormControl, Select as Selectable, MenuItem, InputLabel, makeStyles } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import GridItem from 'components/Grid/GridItem'
import { matchLogConstants } from 'shared/matchData/matchData'
import { LOG_BY_TYPE_RESET, LOG_SEARCH_RESET } from 'local_redux/constants/logConstants'
import { getLogSearch, getLogsByType } from 'local_redux/actions/logActions'
import styles from '../styles/reportScreenStyles'

const useStyles = makeStyles(styles)

const ReportFilter = ({ logType, setIsErrorType, filter, setFilter }) => {
  const dispatch = useDispatch()
  const classes = useStyles(styles)

  const { successLogByType, logByTypeData } = useSelector((state) => state.logByType)
  const { logSearchData } = useSelector((state) => state.logSearch)

  useEffect(() => {
    if (filter.startDate && filter.endDate && filter.log && filter.type)
      dispatch(
        getLogSearch({
          ...filter,
          startDate: format(new Date(filter.startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(filter.endDate), 'yyyy-MM-dd'),
        })
      )
  }, [filter])
  useEffect(() => {
    if (filter.type && !successLogByType) {
      let query = {
        action: filter.type === 'Error' ? 'error' : 'action',
        logType,
      }

      dispatch(getLogsByType(query))
    }
  }, [filter, successLogByType])
  useEffect(() => {
    if (filter.log) {
      setFilter({ ...filter, log: '' })
    }
  }, [filter.type])
  useEffect(() => {
    return () => dispatch({ type: LOG_BY_TYPE_RESET })
  }, [dispatch])

  return (
    <>
      <GridItem xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel id='log-type-select'>Tipo *</InputLabel>
          <Selectable
            MenuProps={{
              className: classes.selectMenu,
            }}
            labelId='log-type-select'
            id='log-type'
            label='Tipo *'
            value={filter.type}
            onChange={(e) => {
              if (filter.type) {
                dispatch({ type: LOG_BY_TYPE_RESET })
              }
              if (logSearchData) {
                dispatch({ type: LOG_SEARCH_RESET })
              }
              setFilter({ ...filter, type: e.target.value })
              setIsErrorType(e.target.value)
            }}
          >
            {['Acción', 'Error'].map((entityType, index) => (
              <MenuItem
                value={entityType}
                key={index}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                {entityType}
              </MenuItem>
            ))}
          </Selectable>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel id='log-select'>Log *</InputLabel>
          <Selectable
            MenuProps={{
              className: classes.selectMenu,
            }}
            labelId='log-select'
            id='log-select'
            label='Log *'
            value={successLogByType ? filter.log : ''}
            disabled={!successLogByType}
            onChange={(e) => setFilter({ ...filter, log: e.target.value })}
          >
            {successLogByType && logByTypeData.length < 1 ? (
              <MenuItem disabled={true}>No se encontraron logs</MenuItem>
            ) : (
              logByTypeData?.map((entityType, index) => (
                <MenuItem
                  value={entityType}
                  key={index}
                  classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                >
                  {matchLogConstants(entityType)}
                </MenuItem>
              ))
            )}
          </Selectable>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <DatePicker
          fullWidth
          disableFuture
          value={filter.startDate}
          onChange={(e) => setFilter({ ...filter, startDate: e })}
          label='Fecha Inicio *'
          className={classes.dateInput}
        />
      </GridItem>
      <GridItem xs={12} sm={3}>
        <DatePicker
          open={filter.startDate && !filter.endDate ? true : false}
          fullWidth
          disableFuture
          minDate={filter.startDate}
          minDateMessage={'Por favor revise este valor'}
          value={filter.endDate}
          onChange={(e) => {
            setFilter({ ...filter, endDate: e })
          }}
          label='Fecha Fin *'
          className={classes.dateInput}
        />
      </GridItem>
    </>
  )
}

export default ReportFilter
