import { matchRoleName } from 'shared/matchData/matchData'

export const reportScreenMapper = ({ logSearchData, value }) => {
	if (value === 'investment') {
		return logSearchData.map((item) => {
			return {
				id: item._id,
				projectAddress: item.project?.projectAddress,
				user: item.user?.name,
				profile: item?.investors?.businessName,
				mount: item?.investment?.amount,
				error: item?.type?.error?.errorMessage,
				reason: item?.reason || '',
				date: item.createdAt,
			}
		})
	} else {
		return logSearchData.map((item) => {
			return {
				id: item._id,
				email: item.user.email,
				name: item?.user?.name,
				surname: item?.user?.lastName || '-----',
				role: matchRoleName(item?.user?.role),
				error: item?.type?.error?.errorMessage || '-----',
				date: item.createdAt,
				hour: item.createdAt,
			}
		})
	}
}
