import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment} from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import DeleteUserModal from 'components/DeleteUserModal/DeleteUserModal'
import UpdateUserModal from './components/UpdateUserModal'
import { usersListColumn } from './helpers/usersListColumn'
import { usersListMapper } from './mappers/usersListMapper'
import { getUsers } from 'local_redux/actions/userActions'
import { USER_LIST_RESET } from 'local_redux/constants/userConstants'
import styles from './styles/userListScreenStyles'

const useStyles = makeStyles(styles)

const UsersListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [data, setData] = useState([])
	const [updateUserModal, setUpdateUserModal] = useState(false)
	const [showUpdateUser, setShowUpdateUser] = useState({})
	const [deleteUserModal, setDeleteUserModal] = useState(false)
	const [showDeleteUserInfo, setShowDeleteUserInfo] = useState({})
	const [usersList, setUsersList] = useState([])
	const [idUser, setIdUser] = useState('')

	const { loadingUserList, users, successUserList, errorUserList } = useSelector(({ userList }) => userList)
	const { successUserUpdate, userUpdated } = useSelector(({ userUpdate }) => userUpdate)
	const { successUserDelete } = useSelector(({ userDelete }) => userDelete)

	useEffect(() => {
		dispatch(getUsers())
	}, [])
	useEffect(() => {
		if (successUserList) {
			setUsersList(users)
		}
	}, [successUserList])
	useEffect(() => {
		if (usersList) {
			setData(usersListMapper(usersList))
		}
	}, [usersList, setIdUser, users])
	useEffect(() => {
		if (successUserUpdate) {
			const updateData = usersList.map((data) => {
				if (data._id === userUpdated._id) {
					data.address = userUpdated.address
					data.avatar = userUpdated.avatar
					data.createdAt = userUpdated.createdAt
					data.email = userUpdated.email
					data.gender = userUpdated.gender
					data.isAdmin = userUpdated.isAdmin
					data.isDefaultPassword = userUpdated.isDefaultPassword
					data.isSuper = userUpdated.isSuper
					data.isMarketing = userUpdated.isMarketing
					data.isContabilidad = userUpdated.isContabilidad
					data.isInvestorRelations = userUpdated.isInvestorRelations
					data.isManagement = userUpdated.isManagement
					data.isInformation = userUpdated.isInformation
					data.name = userUpdated.name
					data.phone = userUpdated.phone
					data.role = userUpdated.role
					return data
				}
				return data
			})
			setUsersList(updateData)
		}
		if (successUserDelete) {
			if (idUser) {
				const userList = usersList.filter((data) => data._id !== idUser)
				setUsersList(userList)
			}
		}
	}, [successUserUpdate, successUserDelete, setUsersList, idUser])
	useEffect(() => {
		return () => dispatch({ type: USER_LIST_RESET })
	}, [])

	const showUpdateUserHandler = (id) => {
		const user = usersList.find((user) => user._id === id)
		setShowUpdateUser(user)
		setUpdateUserModal(true)
	}
	const handleCloseModal = () => {
		setUpdateUserModal(false)
		setShowUpdateUser({})
	}
	const showDeleteInfoHandler = (id) => {
		const user = usersList.find((user) => user._id === id)
		setShowDeleteUserInfo(user)
		setDeleteUserModal(true)
	}
	const handleCloseDeleteUserModal = () => {
		setDeleteUserModal(false)
		setShowDeleteUserInfo({})
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Usuarios</h4>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								data={data}
								columns={usersListColumn({ showUpdateUserHandler, showDeleteInfoHandler, setIdUser })}
								loading={Boolean(loadingUserList)}
								success={Boolean(successUserList)}
								error={errorUserList}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			{updateUserModal && (
				<UpdateUserModal
					handleCloseModal={handleCloseModal}
					updateUserModal={updateUserModal}
					showUpdateUser={showUpdateUser}
				/>
			)}
			{deleteUserModal && (
				<DeleteUserModal
					handleCloseDeleteUserModal={handleCloseDeleteUserModal}
					deleteUserModal={deleteUserModal}
					showDeleteUserInfo={showDeleteUserInfo}
				/>
			)}
		</>
	)
}

export default UsersListScreen
