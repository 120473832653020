import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import { LOG_BY_TYPE_RESET, LOG_SEARCH_RESET } from 'local_redux/constants/logConstants'
import { getLogSearch } from 'local_redux/actions/logActions'
import styles from '../styles/reportFilterButtonStyles'

const useStyles = makeStyles(styles)

const ReportFilterButton = ({ setFilter, initialState, filter, setIsErrorType }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { successLogSearch } = useSelector((state) => state.logSearch)
  return (
    <GridContainer>
      <GridItem xs={6} sm={6} className={classes.gridButton}>
        <Button
          block
          disabled={!filter.type && !filter.log}
          onClick={() => {
            setFilter(initialState)
            dispatch({ type: LOG_BY_TYPE_RESET })
            setIsErrorType('action')
            if (successLogSearch) {
              dispatch({ type: LOG_SEARCH_RESET })
            }
          }}
        >
          Limpiar Filtros
        </Button>
      </GridItem>
      <GridItem xs={6} sm={6} className={classes.gridButton}>
        <Button
          block
          disabled={!filter.log || !filter.startDate || !filter.endDate}
          onClick={() =>
            dispatch(
              getLogSearch({
                ...filter,
                startDate: format(new Date(filter.startDate), 'yyyy-MM-dd'),
                endDate: format(new Date(filter.endDate), 'yyyy-MM-dd'),
              })
            )
          }
        >
          Cargar Datos
        </Button>
      </GridItem>
    </GridContainer>
  )
}

export default ReportFilterButton
